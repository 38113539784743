<template>
  <!--begin::Login Sign in form-->
  <div class="login-signin">
    <div class="mb-10">
      <h3>欢迎登录科易博OA系统</h3>
      <p class="opacity-60 font-weight-bold" style="width: 350px;">请输入您的账号和密码</p>
    </div>
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <div role="alert" v-if="errors" v-bind:class="{ show: errors.length }" class="alert fade alert-danger" style="width: 350px;">
        <div class="alert-text" v-for="(error, i) in errors" :key="i">
          {{ error }}
        </div>
      </div>
      <b-form-group id="example-input-group-1" label="" label-for="example-input-1">
        <b-form-input class="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8 mb-5" id="example-input-1" name="example-input-1" v-model="$v.form.name.$model" :state="validateState('name')"
        aria-describedby="input-1-live-feedback" placeholder="手机号码"></b-form-input>
        <b-form-invalid-feedback id="input-1-live-feedback">
          请填写手机号码
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group id="example-input-group-2" label="" label-for="example-input-2">
        <b-form-input class="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8 mb-5" type="password" id="example-input-2" name="example-input-2" v-model="$v.form.password.$model"
        :state="validateState('password')" aria-describedby="input-2-live-feedback" placeholder="密码"></b-form-input>
        <b-form-invalid-feedback id="input-2-live-feedback">
          请填写密码
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="form-group d-flex flex-wrap justify-content-between align-items-center px-8">
        <div class="checkbox-inline">
          <label class="checkbox checkbox-outline checkbox-white text-white m-0">
            <input type="checkbox" name="remember" />
            <span></span>记住密码</label>
        </div>
        <router-link to="/editPsd">
          <a href="javascript:;" id="kt_login_forgot" class="text-white font-weight-bold">忘记密码 ?</a>
        </router-link>
      </div>
      <div class="form-group text-center mt-10">
        <button type="submit" ref="kt_login_signin_submit" class="btn btn-pill btn-outline-white font-weight-bold opacity-90 px-15 py-3">登录</button>
      </div>
    </b-form>
    <div class="mt-10">
      <span class="opacity-70 mr-4">还没有账号?</span>
      <router-link to="/register">
        <a href="javascript:;" id="kt_login_signup" class="text-white font-weight-bold">注册</a>
      </router-link>
    </div>
  </div>
  <!--end::Login Sign in form-->
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { LOGIN } from "@/core/services/store/auth.module";
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      // Remove this dummy login info
      form: {
        name: "",
        password: ""
      }
    };
  },
  validations: {
    form: {
      name: {
        required
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        name: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const name = this.$v.form.name.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      // this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { name, password })
          // go to which page after successfully login
          .then(() => this.$router.push({ name: "dashboard" }));

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
